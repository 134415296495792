import React, { useEffect, useState } from "react";
import Header from "./Header";
import Logo from "../assets/logo.png";
import About from "./About";
import Portfolio from "./Portfolio";
import Jasa from "./Jasa";
import ContactUs from "./ContactForm";
import ShortDescription from "./ShortDescription";
import { useSpring, animated } from "react-spring";
import bg from "../assets/bg/1222228_original_1440x2560.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const Dashboard = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '100vh',
        margin: 0,
        padding: 0,
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Header
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 1000,
        }}
      />
      <animated.div
        style={{
          ...fadeIn,
          marginTop: '380px',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <animated.div
          className="relative flex flex-col items-start p-4 bg-cover bg-center bg-no-repeat"
          style={{ marginLeft: '-40px' }}
        >
          <div className="flex flex-col items-start">
            <animated.img
              src={Logo}
              alt="Logo"
              className="w-auto h-70 max-h-80 transform rotate-y-10 group-hover:rotate-y-0 transition-transform duration-300"
              style={{fadeIn, opacity: 1}}
            />
            <h2 className="mt-2 text-[#021C41] font-bold" style={{ fontSize: '46px', textAlign: 'center', width: '100%' }}>
              Nookadelic
            </h2>
          </div>
          <br />
          <p className="absolute bottom-0 right-0 mb-4 mr-4 text-[#001026] font-bold">
            DESIGN, BUILD, CONSULT
          </p>
        </animated.div>

        <div className="p-4 shadow-md">
        <div id="about-section"></div>
          <br />
          <br />
          <br />
          <About />
          <div id="shortdescription-section"></div>
          <br />
          <br />
          <br />
          <ShortDescription />
          <div id="jasa-section"></div>
          <br />
          <br />
          <br />
          <Jasa />
          <div id="portfolio-section"></div>
          <br />
          <br />
          <br />
          <Portfolio />
          <div id="contact-section"></div>
          <br />
          <br />
          <br />
          <ContactUs />
        </div>
      </animated.div>
      <footer
       style={{
        position: 'fixed',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
        width: '100%',
        padding: '10px 0',
        background: 'rgba(255, 255, 255, 0.8)', // Optional: Add a semi-transparent background
        zIndex: 1000, // Ensure it stays above other content
      }}>
         <p style={{ margin: 0, color: '#021C41', fontWeight: 'bold' }}>© Nookadelic 2023</p>
      </footer>
      <br />
    </div>
  );
};

export default Dashboard;
