import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import Logo from "../assets/download.jpeg";

const Navbar = () => {
  const location = useLocation();

  const isPostDataPage = location.pathname === "/032190-iksad0213-120";
  const isDataListPage = location.pathname === "/021931230-isdaad0213-120";
  const isEditDataListPage = location.pathname === "/12390-sad89213-901239";

  return (
    <nav className="bg-black w-full fixed top-0 left-0 right-0  z-10">
        <div className="flex items-center justify-between p-4">
          {/* <Link
            to="/"
            className="text-white text-lg font-bold flex items-center pb-2"
          >
            <img src={Logo} alt="Logo" className="w-auto h-12 mr-2" />
            Nookadelic
          </Link> */}
          <div className="hidden md:flex space-x-4">
            <Link
              className="text-white hover:text-gray-300 focus:outline-none"
              to="/"
            >
              Log Out
            </Link>
            {isDataListPage && (
              <Link
                className="text-white hover:text-gray-300 focus:outline-none"
                to="/032190-iksad0213-120"
              >
                Post Data
              </Link>
            )}
            {isEditDataListPage && (
              <Link
                className="text-white hover:text-gray-300 focus:outline-none"
                to="/021931230-isdaad0213-120"
              >
                Back
              </Link>
            )}

            {isPostDataPage && (
              <Link
                className="text-white hover:text-gray-300 focus:outline-none"
                to="/021931230-isdaad0213-120"
              >
                Data List
              </Link>
            )}
          </div>
        </div>
    </nav>
  );
};

export default Navbar;
