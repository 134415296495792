import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ccsgwtk",
        "template_zw8mita",
        form.current,
        "H0ft61hnbWo09XoKq"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="mx-auto mt-8 p-4 mb-3">
      <h2 className="text-3xl font-semibold mb-4 text-[#021C41] text-center">
        Contact us
      </h2>
      <form
        ref={form}
        onSubmit={sendEmail}
        className="max-w-md mx-auto mt-8 bg-white p-4 rounded-lg shadow-md"
      >
        <div className="mb-4">
          <label
            htmlFor="user_name"
            className="text-gray-800 block font-bold mb-2"
          >
            Name
          </label>
          <input
            type="text"
            id="user_name"
            name="user_name"
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="user_email"
            className="text-gray-800 block font-bold mb-2"
          >
            Email
          </label>
          <input
            type="email"
            name="from_name" 
            id="from_name"
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="message"
            className="text-gray-800 block font-bold mb-2"
          >
            Message
          </label>
          <textarea
            id="message"
            name="message"
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        <div className="mb-4">
          <input
            type="submit"
            value="Send Email"
            className="w-full px-4 py-2 bg-[#021C41] text-white rounded-lg hover:bg-blue-600 cursor-pointer"
          />
        </div>
        <div className="text-center mt-4">
          <p className="mb-2">or</p>
          <a
            href="https://wa.me/+6281286357807?text=Halo%20Saya%20ingin%20tahu%20lebih%20lanjut%20mengenai%20nookadelic"
            target="_blank"
            rel="noopener noreferrer"
            className="text-green-800 hover:text-green-600"
          >
            WhatsApp
          </a>
        </div>
       </form>
    </div>
    
  );
};

export default ContactUs;
