import React from "react";

const ShortDescription = () => {
  return (
    <section className="container mx-auto mt-8 flex flex-col md:flex-row items-center md:items-start">
      <div className="w-full md:w-1/2 md:text-left text-center mb-4 md:mb-0">
        <h2 className="text-3xl font-semibold mb-4 text-[#021C41] md:w-1/2">
          Our Mission
        </h2>
        <p className="text-[#021C41] md:w-80 md:text-left text-center font-semibold text-xl">
        We aim to help our client's achieve their goals and vision within their budget, no matter what.
        </p>
      </div>
      <div className="w-full md:w-1/2">
      <h2 className="text-3xl font-semibold mb-4 text-[#021C41] md:w-1/2">
        </h2>
        <p className="text-[#021C41] md:text-left text-center mt-12">
        At Nookadelic, our mission is to deliver exceptional craftsmanship and design excellence.
          We strive to create bespoke spaces that reflect our clients' unique personalities and lifestyles while ensuring the highest standards of quality,
          sustainability, and efficiency in every project we undertake.
        </p>
      </div>
    </section>
  );
};

export default ShortDescription;
