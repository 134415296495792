import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
import useSWR, { mutate } from "swr";
import HeaderAdmin from "./HeaderAdmin";

const LaporanList = () => {
  const navigate = useNavigate();
  const fetcher = async () => {
    const response = await axios.get("https://nookadelic.com:6122/getData");
    return response.data;
  };

  const { data, error } = useSWR("card", fetcher);

  // Function to handle delete operation
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://nookadelic.com:6122/deleteData/${id}`,
        {
          withCredentials: true,
        }
      );
      // Revalidate the SWR cache to reflect the updated data
      mutate();
    } catch (error) {
      console.error("Failed to delete the card:", error);
    }
  };

  // Function to handle edit operation
  const handleEdit = (id) => {
    // Use history.push to navigate to the edit page with the ID
    navigate(`/12390-sad89213-901239/${id}`);
  };

  if (error) return <h2>Error loading data...</h2>;

  return (
    <div className="flex-col mt-5">
      <div className="w-full">
      <HeaderAdmin
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 1000, // Ensures header is above other content
        }}
      />
      <br></br>
        <div className="relative shadow rounded-lg mt-3">
          {!data ? (
            <h2>Loading...</h2>
          ) : (
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                <tr>
                  <th className="py-3 px-1 text-center">ID</th>
                  <th className="py-3 px-6 ">Judul</th>
                  <th className="py-3 px-6 ">Link Drive</th>
                  <th className="py-3 px-6 ">Link Foto</th>
                  <th className="py-3 px-6 ">Tanggal dibuat</th>
                  <th className="py-3 px-6 ">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((card, index) => (
                  <tr className="bg-white boreder-b" key={card.id}>
                    <td className="py-3 px-6 text-center">{card.id}</td>
                    <td className="py-3 px-6 font-medium text-gray-900">
                      {card.judul}
                    </td>
                    <td className="py-3 px-6">{card.linkdrive}</td>
                    <td className="py-3 px-6">{card.link}</td>
                    <td className="py-3 px-6">{card.tanggal}</td>
                    <td className="py-3 px-6">
                      <button
                        onClick={() => handleEdit(card.id)}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 mr-2 rounded"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(card.id)}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default LaporanList;
