import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import HeaderAdmin from "./HeaderAdmin";

const EditLaporan = () => {
  const [judul, setJudul] = useState("");
  const [deskripsi, setDeskripsi] = useState("");
  const [link, setlink] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  const getData = useCallback(async () => {
    const response = await axios.get(`https://nookadelic.com:6122/getDatas/${id}`);
    setJudul(response.data.judul);
    setDeskripsi(response.data.linkdrive);
    setlink(response.data.link);
    console.log(response);
  }, [id]);

  useEffect(() => {
    getData();
  }, [id, getData]);

  const saveData = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(
        `https://nookadelic.com/updateData/${id}`,
        {
          judul: judul,
          linkdrive: deskripsi,
          link: link,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response);
      navigate("/021931230-isdaad0213-120");
    } catch (error) {
      if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        console.error("Error response:", error.response.data);
        console.error("Error status:", error.response.status);
        console.error("Error headers:", error.response.headers);
      } else if (error.request) {
        // The request was made, but no response was received
        console.error("Error request:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
      }
      console.error("Error config:", error.config);
    }
  };
  

  return (
    <div className="flex-col mt-5">
      <div className="W-full">
        <HeaderAdmin />
        <div className="max-w-lg mx-auto my-10 bg-white p-8 rounded-xl shadow shadow-slate-300">
          <form onSubmit={saveData} className="my-10">
            <div className="flex flex-col">
              <div className="mb-5">
                <label className="font-bold text-slate-700">Judul</label>
                <input
                  type="text"
                  className="w-full py-3 mt-1 border border-slate-200 rounded-lg px=3 focus:outline-none focus:border-slate-500 hover:shadow"
                  placeholder="Judul Porto"
                  value={judul}
                  onChange={(e) => setJudul(e.target.value)}
                />
              </div>
              <div className="mb-5">
                <label className="font-bold text-slate-700">Link Folder Drive</label>
                <input
                  type="text"
                  className="w-full py-3 mt-1 border border-slate-200 rounded-lg px=3 focus:outline-none focus:border-slate-500 hover:shadow"
                  placeholder="Link Folder "
                  value={deskripsi}
                  onChange={(e) => setDeskripsi(e.target.value)}
                />
              </div>
              <div className="mb-5">
                <label className="font-bold text-slate-700">Link Foto</label>
                <input
                  type="text"
                  className="w-full py-3 mt-1 border border-slate-200 rounded-lg px=3 focus:outline-none focus:border-slate-500 hover:shadow"
                  placeholder="ex : https://xnxx.com/"
                  value={link}
                  onChange={(e) => setlink(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="w-full py-3 font-bold text-white bg-indigo-600 hover:bg-indigo-500 rounded-lg border-indigo-500 hover:shadow"
              >
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditLaporan;
