import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Portfolio from "./components/Portfolio";
import About from "./components/About";
import Contact from "./components/ContactForm";
import Dashboard from "./components/Dashboard";
import SplashScreen from "./components/addons/SplashScreen";
import Post from "./components/adminDashBoard/Post";
import Admin from "./components/adminDashBoard/Admin";
import Login from "./components/adminDashBoard/Login";
import ProtectedPage from "./utils/ProtectedPage";
import withAuth from "./utils/withAuth.js";
import Edit from "./components/adminDashBoard/Edit";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading for a few seconds (you can replace this with actual loading logic)
    setTimeout(() => {
      setLoading(false);
    }, 2600); // Adjust the time as needed

    // Cleanup function
    return () => {
      // No need to set loading to false here
    };
  }, []);

  return (
    <div>
      <Router>
        {/* Conditionally render the SplashScreen while loading */}
        {loading ? (
          <SplashScreen />
        ) : (
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/032190-iksad0213-120" element={<PostWithAuth />} />
            <Route
              path="/12390-sad89213-901239/:id"
              element={<EditWithAuth />} // Use `element` instead of `component`
            />
            <Route
              path="/021931230-isdaad0213-120"
              element={<AdminWithAuth />}
            />
            <Route path="/admin" element={<Login />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}

const PostWithAuth = withAuth(Post);
const AdminWithAuth = withAuth(Admin);
const EditWithAuth = withAuth(Edit);

export default App;
