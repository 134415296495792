import React from "react";

const About = () => {
  return (
    <section className="container mx-auto mt-8 flex flex-col md:flex-row items-center justify-center">
      <div className="w-full md:w-1/2 text-center md:text-center justify-center mb-4 md:mb-0">
        <h2 className="text-3xl font-semibold mb-4 text-[#021C41]">
          About Us
        </h2>
        <p className="text-[#021C41] text-center  justify-center">
          Nookadelic is a premier construction and interior design company dedicated to transforming ordinary spaces into extraordinary environments. With a seamless blend of cutting-edge construction techniques and innovative interior design, we bring our client's visions to life, creating spaces that are not only functional but also aesthetically captivating.
        </p>
      </div>
      {/* <div className="w-full md:w-1/6"></div> */}
      {/* <section className="container mx-auto flex flex-col md:flex-row items-start justify-end">
      <div className="w-full md:w-1/2 ml-30 mr-10">
        <h2 className="text-3xl font-semibold mb-4 text-[#021C41] text-left">
          Our Mission
        </h2>
        <p className="text-[#021C41] text-right md:text-left text-center">
          At Nookadelic, our mission is to deliver exceptional craftsmanship and design excellence.
          We strive to create bespoke spaces that reflect our clients' unique personalities and lifestyles while ensuring the highest standards of quality,
          sustainability, and efficiency in every project we undertake.
        </p>
      </div>
    </section> */}
      {/* <div className="w-full md:w-1/3 md:text-left justify-end">
      <h2 className="text-3xl font-semibold mb-4 text-[#021C41]">
          Our Mission
        </h2>
        <p className="text-[#021C41] md:text-left text-center">
        At Nookadelic, our mission is to deliver exceptional craftsmanship and design excellence.
          We strive to create bespoke spaces that reflect our clients' unique personalities and lifestyles while ensuring the highest standards of quality,
          sustainability, and efficiency in every project we undertake.
        </p>
      </div> */}
    </section>
  );
};


export default About;
