import React from "react";
import house from "../assets/house.png";
import interior from "../assets/interior.jpg";
import konsultan from "../assets/konsultan.jpeg";

const Jasa = () => {
  return (
    <section className="container mx-auto mt-8 text-[#021C41]">
      <h2 className="text-3xl font-semibold mb-4 text-left">Layanan & Jasa</h2>
      <div className="flex justify-center">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
          {/* Service 1 */}
          <div className="flex items-center justify-center">
            <div
              className="bg-white p-4 shadow-lg transform hover:translate-y-2 transition-transform duration-300 rounded-lg text-center"
              style={{ width: "210px", height: "260px" }}
            >
              <img
                src={interior}
                alt="Design & Build Interior"
                className="w-full h-auto mb-4"
              />
              <h3 className="text-gray-600 font-bold">Design & Build Interior</h3>
            </div>
          </div>

          {/* Service 2 */}
          <div className="flex items-center justify-center">
            <div
              className="bg-white p-4 shadow-lg transform hover:translate-y-2 transition-transform duration-300 rounded-lg text-center"
              style={{ width: "210px", height: "260px" }}
            >
              <img
                src={house}
                alt="Design & Build Konstruksi"
                className="w-full h-auto mb-4"
              />
              <h3 className="text-gray-600 font-bold">Design & Build Konstruksi</h3>
            </div>
          </div>

          {/* Service 3 */}
          <div className="flex items-center justify-center">
            <div
              className="bg-white p-4 shadow-lg transform hover:translate-y-2 transition-transform duration-300 rounded-lg text-center"
              style={{ width: "210px", height: "260px" }}
            >
              <img
                src={konsultan}
                alt="Konsultasi"
                className="w-full h-auto mb-4"
              />
              <h3 className="text-gray-600 font-bold">Konsultasi</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Jasa;
