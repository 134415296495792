import Cookies from "js-cookie";

export const isAuthenticated = async () => {
  const token = await Cookies.get("token");
  console.log(token);
  // You may want to add additional checks like token expiration here
  return token !== undefined && token !== null;
};

export default isAuthenticated;
