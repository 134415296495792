import React, { useState, useEffect } from "react";
import axios from "axios";

const Portfolio = () => {
  const [portfolioItems, setPortfolioItems] = useState([]);

  useEffect(() => {
    // Fetch data from the API using axios
    axios
      .get("https://nookadelic.com:6122/getData")
      .then((response) => {
        // Assuming the API response has a structure like [{ judul, deskripsi, link }, ...]
        const fetchedPortfolioItems = response.data.map((item) => ({
          title: item.judul,
          link: item.linkdrive,
          image: item.link,
        }));
        setPortfolioItems(fetchedPortfolioItems);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []); // The empty dependency array ensures the effect runs only once, similar to componentDidMount

  return (
    <section className="container mx-auto mt-8">
      <h2 className="text-3xl font-semibold mb-4 text-[#021C41] text-left">
        Portfolio
      </h2>
      <div className="flex justify-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-items-center">
          {portfolioItems.map((item, index) => (
            <div
              key={index}
              className="bg-white p-4 transform hover:translate-y-2 hover:scale-105 transition-transform duration-300 rounded-lg shadow-md"
            >
              {console.log('Rendering Image URL:', item.image)} {/* Log the image URL */}
              <img
                src={item.image}
                alt={item.title}
                className="w-full h-auto mb-4"
              />
              <h3 className="text-gray-600 font-sans font-bold text-center mb-2">
                {item.title}
              </h3>
              <div className="text-center">
                <a
                  className="text-gray-600 hover:text-blue-500"
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click To View More
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
