import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "./tokenValidate";

const withAuth = (WrappedComponent, redirectPath = "/admin") => {
  return class extends React.Component {
    render() {
      if (isAuthenticated()) {
        console.log(isAuthenticated);
        return <WrappedComponent {...this.props} />;
      } else {
        console.log(isAuthenticated);
        return <Navigate to={redirectPath} />;
      }
    }
  };
};

export default withAuth;
